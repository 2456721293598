@import "colors";

.about {
  width: 100%;
  background-color: $pink;

  > main {
    background-color: white;
    max-width: 900px;
    height: 100%;
    margin: auto;

    > h1 {
      font: 500 3rem "Roboto";
      color: $red;
      padding: 1rem 2rem;
      text-transform: uppercase;
      background-color: $dark;
    }

    > article {
      margin: 2rem 0;
      > h4 {
        font: 100 2rem "Roboto";
        text-transform: uppercase;
        text-align: center;
        padding: 2rem 0;
      }

      > p {
        letter-spacing: 3px;
        word-spacing: 3px;
        padding: 2rem;
        width: 50%;
        text-align: center;
        margin: auto;
      }

      > a {
        background-color: $dark;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5rem;
        height: 2.5rem;
        color: white;
        font-size: 1.5rem;
        transition: all 0.3s;
        margin: auto;
        &:hover {
          color: $red;
          transform: scale(1.1);
        }
      }
    }

    > div {
      background-color: $dark;
      padding: 2rem;
      color: white;
      > h2 {
        text-align: center;
        text-transform: uppercase;
      }

      > article {
        display: flex;
        align-items: center;
        justify-content: center;

        > div {
          margin: 2rem;
          > img {
            width: 150px;
            height: 150px;
            border-radius: 100%;
          }
          > h3 {
            margin: 1rem;
          }
        }
        > p {
          margin: 0 1rem;
          width: 50%;
          letter-spacing: 2px;
          line-height: 150%;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .about > main {
    > h1 {
      font: 100 3rem "Roboto";
      text-align: center;
    }

    > article > p {
      width: 100%;
    }

    > div > article {
      flex-direction: column;

      > p {
        text-align: center;
      }
    }
  }
}
