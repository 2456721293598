@import "colors";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&family=Tenor+Sans&family=Ubuntu:wght@300;400;500;700&display=swap");
* {
    margin: 0%;
    padding: 0%;
    font-family: "Ubuntu", sans-serif;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  
  a {
    text-decoration: none;
  }
  
  .notFound {
    background-color: $pink;
    width: 100%;
    height: 80vh;
    > main {
      background-color: white;
      height: 100%;
      max-width: 900px;
      margin: auto;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
  
      > div {
        display: flex;
        align-items: center;
        margin: 3rem;
  
        > svg {
          font-size: 4rem;
        }
  
        > h1 {
          text-transform: uppercase;
          align-items: center;
          font: 100 3rem "Roboto";
          margin: 0 1rem;
        }
      }
  
      > a {
        background-color: $red;
        color: white;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        margin: 1rem;
        text-align: center;
        font-size: 1.1rem;
      }
    }
  }
  
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    > svg {
      font-size: 15rem;
      color: $red;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.3;
    }
  
    > div {
      font-size: 2rem;
    }
  }
  