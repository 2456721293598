@import "colors";

.login {
  height: 100vh;
  width: 100%;
  background-image: url("../assets/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  > button {
    background-color: white;
    color: $lightdark;
    border: none;
    border-radius: 10px;
    padding: 1rem 2rem;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      margin-left: 1rem;
    }

    &:hover {
      color: $dark;
    }
  }
}
