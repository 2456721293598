@import "colors";

.shipping {
  background-color: $pink;
  width: 100%;
  height: 100vh;
  > main {
    height: 100%;
    background-color: white;
    max-width: 900px;
    margin: auto;
    padding: 2rem;

    > h1 {
      text-transform: uppercase;
      text-align: center;
      margin: 3rem;
      font: 100 3rem "Roboto";
    }

    > form {
      display: flex;
      flex-direction: column;
      align-items: center;

      > div {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > label {
          white-space: nowrap;
        }
        > input,
        > select {
          width: 450px;
          padding: 0.5rem 1rem;
          outline: none;
          border: 1px solid rgba(0, 0, 0, 0.172);
          border-radius: 5px;
          margin: 1rem;
          font-size: 1rem;
        }
      }
      > button {
        background-color: $red;
        color: white;
        outline: none;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        margin: 1rem;
        text-align: center;
        cursor: pointer;
        font-size: 1.1rem;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .shipping > main > form {
    > div {
      width: 100%;
      > input,
      > select {
        width: 100%;
      }
    }

    > button {
      width: 100%;
    }
  }
}
