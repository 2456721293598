@import "colors";

.paymentsuccess {
  background-color: $pink;
  width: 100%;
  height: 80vh;
  > main {
    height: 100%;
    background-color: white;
    max-width: 900px;
    margin: auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    > h1 {
      text-transform: uppercase;
      text-align: center;
      margin: 3rem;
      font: 100 3rem "Roboto";
    }

    > a {
      background-color: $red;
      border: none;
      outline: none;
      color: white;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      margin: 1rem;
      text-align: center;
      cursor: pointer;
      font-size: 1.1rem;
    }
  }
}
